<template>
  <div>
    <Grid
      route_name="notifications"
      order_column="created_at"
      :hide_searchbar="true"
      :service="service"
      @grid-UPDATED="gridChanged"
      @selected="viewNotification($event)"
      ref="grid"
    >
      <template v-slot:gridThead>
        <vs-th key="icon"></vs-th>
        <vs-th key="title">{{ $t('classes_title') }}</vs-th>
        <vs-th key="message">{{ $t('conteudo') }}</vs-th>
        <vs-th key="created_at" sort-key="created_at">{{ $t('data-0') }}</vs-th>
      </template>
      <template v-slot:gridActions>
        <div></div>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :class="formatStatus(tr)" :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="icon">
            <feather-icon :icon="formatIconName(tr.dropdownIcon)" :svgClasses="[`text-${tr.dropdownCategory}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>
          </vs-td>
          <vs-td width="25%" key="title" :class="[`text-${tr.dropdownCategory}`]">{{ tr.dropdownTitle }}</vs-td>
          <vs-td width="50%" key="message">{{tr.message(50)}}</vs-td>
          <vs-td width="20%" key="created_at">{{ formatDate(tr.created_at) }}</vs-td>
        </vs-tr>
      </template>
    </Grid>

    <vs-popup
        :title="$t('detalhes-notificacao')"
        :active.sync="activeDetails"
    >
      <div class="grid grid-cols-12">
        <div class="mb-4 col-span-12 justify-self-center">
          <div class="flex items-center">
            <feather-icon v-if="notificationDetails.dropdownIcon" :icon="formatIconName(notificationDetails.dropdownIcon)" :svgClasses="[`text-${notificationDetails.dropdownCategory}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>
            <span :class="`text-${notificationDetails.dropdownCategory} font-bold text-lg`" v-if="notificationDetails.dropdownTitle">{{notificationDetails.dropdownTitle}}</span>
          </div>
        </div>
        <span class="text-lg text-center font-bold col-span-12 border-grey border-b border-t-0 border-r-0 border-l-0 border-solid">{{title}}</span>
        <div class="mb-3 mt-4 col-span-12"
           v-if="notificationDetails.type === 'answer.comment' && notificationDetails.comment.questionnaire_name"
        >
          <span class="block font-bold mb-1">{{ $t('Questionnaire') }}</span>
          <a class="block mb-2" target="_blank" :href="notificationDetails.comment.answer_url">
            {{notificationDetails.comment.questionnaire_name}}
          </a>
          <div
            class="block mb-1"
            v-if="notificationDetails.comment.author_name"
          >
            <span class="font-bold">Autor: </span>
            <span>{{notificationDetails.comment.author_name}}</span>
          </div>

        </div>
        <span v-if="notificationDetails.type === 'answer.comment'" class="text-lg text-center font-bold col-span-12 border-grey border-b border-t-0 border-r-0 border-l-0 border-solid"></span>
        <div v-if="notificationDetails.type === 'answer.comment'" class="my-4 col-span-12 justify-self-start grid break-all">
          <div v-html-safe="notificationDetails.message()" v-if="notificationDetails.message"></div>
        </div>
        <div v-else class="my-4 col-span-12 justify-self-start grid break-all">
          <span v-if="notificationDetails.message">{{notificationDetails.message()}}</span>
        </div>
        <vs-button
          class="justify-self-end col-span-12"
          v-if="notificationDetails.onClick && notificationDetails.type !== 'answer.comment'"
          color="primary"
          @click="openNotificationAction(notificationDetails)"
        >{{ $t('abrir') }}</vs-button>
        <span class="justify-self-center col-span-12" v-else >{{url}}</span>
      </div>
    </vs-popup>
  </div>
</template>

<script>
import NotificationService from '@/services/api/NotificationService'

export default {

  props : {
    injectService: {
      type: Object,
      default: null
    }
  },

  data: () => ({
    service: null,
    notificationDetails: {
      title: 'nothing'
    },
    activeDetails: false
  }),

  methods: {
    formatIconName(icon) {
      return this.$utils.format.featherIcon.fromNotify(icon)
    },
    formatDate(date) {
      return this.$utils.format.date(date)
    },
    formatStatus(notification) {
      if (this.isEmpty(notification.read_at)) {
        return 'border-solid font-bold border-primary border-l-8 border-r-0 border-t-0 border-b-0'
      }
    },
    viewNotification(notification) {
      notification.clickOptions = {ignoreMarkRead: true}
      this.service.markAsRead(notification).then((success) => {
        this.$refs.grid.fetchGridData()
        this.notificationDetails = { ...notification}
        this.activeDetails = true
      },
      (error) => {
        this.notifyError(this.$vs, this.$t('erro-nao-foi-possivel-marcar-a-notificac'))
        this.notificationDetails = notification
        this.activeDetails = true
      })
    },
    openNotificationAction(notification) {
      this.activeDetails = false
      notification.onClick(this.service, notification)
    }
  },

  created() {
    this.$root.$on('notificationArrived', () => {
      const grid = this.$refs.grid
      if (!this.isEmpty(grid)) {
        grid.fetchGridData()
      }
    })
    this.service = (this.injectService === null) ?
      this.service = NotificationService.build(this) :
      this.service = this.injectService
  }
}
</script>

<style>

</style>
